import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginDataEmptyAction, signUpRequest } from "../../redux/actions/authAction";
import { IRootState } from "../../redux/reducer";
import { PRIVACYPOLICY } from "../../routes/routesConstant";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Link } from "react-router-dom";
const SignUp = () => {
    const dispatch = useDispatch();
    const [passwordType, setPasswordType] = useState(false);
    const [confirmPasswordType, setConfirmPasswordType] = useState(false);
    const [formData, setFormData] = useState({
        username: "",
        mobile_no: "",
        email: "",
        password: "",
        confirm_password: "",
        agree: false,
        signup_type: 1
    });
    const [error, setError] = useState('');
    const { loginData } = useSelector<IRootState, any>((state) => state.authReducer);
    const submit = () => {
        let error;
        if (!formData.email) {
            error = "Email is required";
        } else if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formData.email))) {
            error = 'Please enter valid email';
        }
        // else if (!formData.mobile_no) {
        //     error = "Mobile Number is required";
        // }
        else if (!formData.password) {
            error = "Password is empty";
        } else if (!formData.confirm_password) {
            error = "Confirm Password is required";
        } else if (formData.password !== formData.confirm_password) {
            error = "Password and Confirm Password are not same";
        }
        else if (!formData.agree) {
            error = "Please accept the privacy policy";
        }
        if (error) {
            setError(error);
            return;
        } else {
            setError('')
        }
        dispatch(signUpRequest(formData, 'signup'))
    };

    useEffect(() => {
        if (!loginData?.status_code && loginData?.accessType === "signup") {
            setError(loginData?.error || 'Unable to sign up')
        } else if (loginData?.status_code && loginData?.accessType === "signup") {
            toast(loginData?.message);
            setTimeout(() => { dispatch(loginDataEmptyAction()); }, 2000);
            setFormData({
                username: "",
                mobile_no: "",
                email: "",
                password: "",
                confirm_password: "",
                agree: false,
                signup_type: 1
            });
        }
    }, [loginData]);

    return (
        <div className="loginrgstr_col">
            <div className="loginrgstr_colalign">
                <div className="loginsignup-form">
                    <div className="loginrgstr_cont">
                        <h3 className="loginrgstr_toptitle">FREE ACCOUNT</h3>
                        <h2>Sign Up</h2>
                    </div>

                    <div className="form-group">
                        <div className="input-group">
                            <input className="form-control floatLabel"
                                id="signup-firstname"
                                type="text"
                                placeholder="Full Name"
                                value={formData?.username}
                                onChange={(e) => {
                                    setFormData({ ...formData, username: e.target.value })
                                }}
                            />
                            {/* <label htmlFor="signup-firstname">Full Name</label> */}
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <input className="form-control floatLabel"
                                id="signup-lastname"
                                type="text"
                                placeholder="Mobile Number (optional)"
                                value={formData?.mobile_no}
                                onChange={(e) => {
                                    setFormData({ ...formData, mobile_no: e.target.value })
                                }}
                            />
                            {/* <label htmlFor="signup-lastname">Mobile Number</label> */}
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <input className="form-control floatLabel"
                                id="signup-email"
                                type="email"
                                placeholder="Email"
                                value={formData?.email}
                                onChange={(e) => {
                                    setFormData({ ...formData, email: e.target.value })
                                }}
                            />
                            {/* <label htmlFor="signup-email">Email</label> */}
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <input className="form-control floatLabel"
                                id="signup-password"
                                type={!passwordType ? "password" : "text"}
                                placeholder="Password"
                                value={formData?.password}
                                onChange={(e) => {
                                    setFormData({ ...formData, password: e.target.value })
                                }}
                            />
                            {/* <label htmlFor="signup-password">Passsword</label> */}
                            <div className="input-group-prepend">
                                <div className="input-group-text" onClick={() => setPasswordType(!passwordType)}>{!passwordType ? 'Show' : 'Hide'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <input className="form-control floatLabel"
                                id="signup-confirmpassword"
                                type={!confirmPasswordType ? "password" : "text"}
                                placeholder="Confirm Password"
                                value={formData?.confirm_password}
                                onChange={(e) => {
                                    setFormData({ ...formData, confirm_password: e.target.value })
                                }}
                            />
                            {/* <label htmlFor="signup-confirmpassword">Confirm Passsword</label> */}
                            <div className="input-group-prepend">
                                <div className="input-group-text" onClick={() => setConfirmPasswordType(!confirmPasswordType)}>{!confirmPasswordType ? 'Show' : 'Hide'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="custom-control common-checkbox checkbox custom-checkbox">
                            <input type="checkbox"
                                className="custom-control-input"
                                id="signupagree"
                                name="example1"
                                checked={formData?.agree}
                                onChange={(e) => {
                                    setFormData({ ...formData, agree: e.target.checked })
                                }}
                            />
                            <label className="custom-control-label" htmlFor="signupagree">
                                <span className="remember_me">I Agree with</span></label>

                            <span><Link className="forgot_pass px-1" to={PRIVACYPOLICY}>privacy</Link>
                                and
                                <Link to={PRIVACYPOLICY} className="forgot_pass px-1">policy</Link>
                            </span>
                        </div>
                    </div>
                    <div className="form-group">
                        <span className="forgot_pass" style={{ "lineHeight": "1", "fontSize": "12px" }}>{error}</span>
                        <button type="button" className="btn btn-block" onClick={submit}>Sign Up</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SignUp;