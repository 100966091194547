import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams, Link } from "react-router-dom";
import Stripe from "stripe";
import { post } from "../../api";

const SuccessView = (props) => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const [stripeSK, setStripeSK] = useState("");
  const { cypher_token } = useSelector((state) => state.initialApiReducer);
  let stripe;
  const session_id = searchParams.get("sid");
  const pre_transaction_id = searchParams.get("pti");

  useEffect(() => {
    if (session_id && cypher_token) {
      let token = atob(cypher_token);
      token = token.split("-");
      stripe = new Stripe(token[0]);
      setStripeSK(token[0]);
      fetchSessionDetails();
    }
  }, [cypher_token]);

  const initiateTransaction = async (session, subscription) => {
    let { payment_status } = session;
    const subscription_status = payment_status === "paid" ? 1 : 0;
    const subscriptionResponse = btoa(JSON.stringify(subscription));
    let response = await post(
      `/complete-subscription?pre_transaction_id=${pre_transaction_id}&post_transaction_id=${subscription.id}&payment_response=${subscriptionResponse}&subscription_status=${subscription_status}`
    );
    if (response?.status_code) {
      setMessage(response?.message);
    }
  };

  const fetchSessionDetails = async () => {
    console.log(session_id, stripeSK);
    const session = await stripe.checkout.sessions.retrieve(session_id);
    const subscription = await stripe.subscriptions.retrieve(
      session.subscription
    );
    initiateTransaction(session, subscription);
  };

  return (
    <div className="subscription_success_page">
      <div className="account_verified">
        <h3 style={{ color: "#fff" }}>{message}</h3>
        <p>
          Go back to <Link to="/"> Home Page</Link>
        </p>
      </div>
    </div>
  );
};

export default SuccessView;
